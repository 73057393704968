/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Experience Luxury Yachting with Four Seasons Yachts"), "\n", React.createElement(_components.p, null, "Are you ready to embark on a luxurious yachting experience like no other? Look no further than Four Seasons Yachts, where opulence meets unparalleled service."), "\n", React.createElement(_components.h2, null, "Unmatched Luxury and Personalized Service"), "\n", React.createElement(_components.p, null, "Four Seasons Yachts offer a truly elite experience, with luxurious suites designed by the renowned Prosper Assouline. From the Greek Isles to the Caribbean, guests can explore stunning destinations in style and comfort."), "\n", React.createElement(_components.h2, null, "Industry-First Features"), "\n", React.createElement(_components.p, null, "These yachts boast industry-first features that guarantee a lavish experience for all guests. Get ready to indulge in the ultimate yachting adventure with Four Seasons Yachts."), "\n", React.createElement(_components.p, null, "For more information, visit ", React.createElement(_components.a, {
    href: "https://www.fourseasonsyachts.com/"
  }, "Four Seasons Yachts"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
